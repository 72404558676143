import React, { createContext, useContext, useState } from "react";
export const TextDataContext = createContext();
export default function TextDataProvider({children}){
    const[TextDataid, setTextDataid]= useState();
    return <TextDataContext.Provider value={{TextDataid,setTextDataid}}>{children}</TextDataContext.Provider>
}
export function useTextData(){
    const context= useContext(TextDataContext)
    const {TextDataid, setTextDataid} = context;
    return {TextDataid, setTextDataid}

}