import logo from './logo.svg';
import './App.css';
import TextDataProvider from './hooks/textData';
import { FormText } from './components/rewrite/Form';
import { RewriteInterface } from './components/rewrite';
import { Footer } from './components/footer/footer';
import { Hero } from './components/landpage/hero/hero';

function App() {
  return (
    <>
      <Hero/>
      <RewriteInterface />
      <Footer />
    </>
  );
}

export default App;
