import React from "react";
import { OriginalContent } from "./OriginalText";
import { FormText } from "./Form";
import TextDataProvider from "../../hooks/textData";
import RewrittedProvider from "../../hooks/rewritedData";
import { UpdateButton } from "./Generate";
import { BotReturnData } from "./NewText";

export function RewriteComponents(){
    return(
        <>
        <TextDataProvider>
            <RewrittedProvider>
                <OriginalContent/>
                <FormText />
                <UpdateButton/>
                <BotReturnData />
                
            </RewrittedProvider>
        </TextDataProvider>
        </>
    )
}