import { useRewritted } from "../../hooks/rewritedData";
import { useTextData } from "../../hooks/textData";

function BotReturnData(){
    const {TextDataid, setTextDataid} =useTextData();
    const {Rewrittedid, setRewrittedid} = useRewritted();
    return(
        <>
        <div>
            <h4>Texto Recriado</h4>

            <div>
                <p>{Rewrittedid["rewritedtext"]}</p>
            </div>
        </div>
        </>
    )
}
export{
    BotReturnData
}