import React from "react";
import { useTextData } from "../../hooks/textData";

export function OriginalContent(){
    const {TextDataid, setTextDataid} =useTextData();
    return(
        <>
        <div>
            <div>
                <h4>Texto Original</h4>
                <div>
                    <p>{TextDataid}</p>
                </div>
            </div>
        </div>
        </>
    )
}