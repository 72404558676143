import React from "react";
import { useTextData } from "../../hooks/textData";
import { useRewritted } from "../../hooks/rewritedData";

export function FormText (){
    const {TextDataid, setTextDataid} =useTextData();
    const {Rewrittedid, setRewrittedid} = useRewritted();

    return(
        <>
        <div>
            <div>
                <textarea style={{
                    width:"90%",
                    height:"300px",
                    float:"left",
                    borderTop:"transparent",
                    borderLeft:"transparent",
                    borderTop:"transparent",
                    borderImage:"initial",
                    padding:"10px 10px 10px 30px",
                    background:'url("https://cdn2.iconfinder.com/data/icons/picol-vector/32/document_text_edit-512.png") 0px 5px / 24px no-repeat transparent'
                }}wrap="hard" type={"text"} autoComplete="off" placeholder={"Digite Seu Texto Aqui "} onChange={e => setTextDataid(e.target.value)} />
            </div>
        </div>
        </>
    )
}