import React from "react";
import { OriginalContent } from "./OriginalText";
import { FormText } from "./Form";
import TextDataProvider from "../../hooks/textData";
import RewrittedProvider from "../../hooks/rewritedData";
import { UpdateButton } from "./Generate";
import { BotReturnData } from "./NewText";
import { RewriteComponents } from "./components";

export function RewriteInterface(){
    return(
        <>
        <div style={{
            width:"100%",
            height:"100%",
            borderRadius:"25px",
            backgroundColor:"rgb(254,254,254)",
        }}>
            <div style={{
                width:"100%",
                display:"flex",
                flexDirection:"column",
                padding:"4%"
            }}>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <RewriteComponents/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}