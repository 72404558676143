import React, { createContext, useContext, useState } from "react";
export const RewrittedContext = createContext();
export default function RewrittedProvider({children}){
    const[Rewrittedid, setRewrittedid]= useState(2);
    return <RewrittedContext.Provider value={{Rewrittedid,setRewrittedid}}>{children}</RewrittedContext.Provider>
}
export function useRewritted(){
    const context= useContext(RewrittedContext)
    const {Rewrittedid, setRewrittedid} = context;
    console.log(Rewrittedid)
    return {Rewrittedid, setRewrittedid}

}