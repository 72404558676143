import React from "react";
import { useTextData } from "../../hooks/textData";
import { useRewritted } from "../../hooks/rewritedData";
import { tkgen } from "../../service/token";

export function UpdateButton(){
    const {TextDataid, setTextDataid} =useTextData();
    const {Rewrittedid, setRewrittedid} = useRewritted();
    function UpdateApi(){
        var authkeys =tkgen(350)
        const info = { 
            textdata:TextDataid,
            autenticator:authkeys,
            mireliauth:authkeys[9],
            gateauth:authkeys,
            cookie:tkgen(290)

        };
        var apiRequest =fetch("https://rewrite.alexandrepicinato.com.br/api.php?mirelitoken="+authkeys+"&reqid="+tkgen(1999), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "mireliAuth":authkeys,
            "ReqKey":tkgen(200)
        },
        body: JSON.stringify(info),
        })
        .then((response) => response.json())
        .then((data) => {
            console.log("Success:", data);
            setRewrittedid(data)
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }
    return(
        <>
        <button  style={{
            background:"linear-gradient(rgb(250, 202, 72) 5%, rgb(230, 185, 37) 100%)",
            boxShadow:"rgb(215, 236, 253) 0px 1px 0px 0px inset"
        }}onClick={e => UpdateApi()}>Gerar Novo Texto </button>
        </>
    )
}