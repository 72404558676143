import React from "react";
export function Footer(){
    return(
        <footer>
        <div style={{
            width:"100%",
            backgroundColor:"rgba(3, 1 , 1)",
            color:"rgba(230 , 230 , 230)",
            padding:"0%"
        }}>
            <div>
            <h4>Criado Por Alexandre Picinato Scherer ©</h4>
            <h4>Criado em 20 de junho de 2023</h4>
            <h4>alexandrepicinato.com.br</h4>
            </div>
        </div>
    </footer>
    )
}