import React from "react";
import ImageBanner from "../../../assents/banner/Desenho112.png"

export function Hero(){
    return(
        <>
        <div style={{
            backgroundImage:"url("+ImageBanner+")",
            backgroundSize:"cover",
            backgroundRepeat:"no-repeat",
            "backgroundPosition":"center left"
        }}>
            <div>
                <div style={{
                    height:"100%",
                    minHeight:"500px",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"

                }}>
                    <div>
                        <div style={{
                            display:"flex",
                            justifyContent:"center"
                        }}>
                            <div style={{
                                width:"100%",
                                height:"100%",
                                display:"flex",
                                justifyContent:"center",
                                flexDirection:"column",
                                alignItems:"flex-end",
                                textAlign:"center",
                                backdropFilter:"blur(2px)",
                                width:"90vw",
                                

                            }}>
                                <div style={{
                                }}>

                                </div>
                                <h1 style={{
                                    fontSize:"3em",
                                    color:"rgb(133,45,255,1)"
                                }}>
                                    Language Magic 
                                </h1>
                                <h4 style={{
                                    color:"rgb(21,1,36)",
                                    fontSize:"1.1em",
                                    
                                }}>
                                    Alguns toques a mais em seus textos podem soar como um toque de magina na sua criatividade 
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}